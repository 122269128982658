import { MENU_ITEMS } from "../const";
import { motion } from "framer-motion";
import { ENVIRONMENT } from "@/utils/environment";
import { Button } from "@nextui-org/react";
import Image from "next/image";

const menus = {
  initial: { y: -400, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      // ease: [0.6, 0.01, -0.05, 0.95],
      // duration: 1,
      delayChildren: 0.2,
      duration: 0.25,
      staggerChildren: 0.15,
    },
  },
};

const menuItem = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
  },
};

const icon = {
  hidden: {
    pathLength: 0,
    fill: "rgba(0, 0, 0, 0)",
  },
  visible: {
    pathLength: 1,
    fill: "rgba(0, 0, 0, 1)",
  },
};

export function MobileMenu({ onClose, onClickTwitter, onClickDiscord }) {
  return (
    <motion.div
      variants={menus}
      initial="initial"
      animate="animate"
      className="font-founders text-white px-4 flex flex-col w-full h-screen bg-[#191919] fixed top-0 left-0 z-50"
    >
      <div className="py-6 flex  items-center justify-between mb-[56px]">
        <a href="/">
          <Image
            unoptimized
            src={`${ENVIRONMENT.ASSETS_URL}/landing/logo.svg`}
            width={100}
            height={26}
            alt="bythen logo"
          />
        </a>
        <Button isIconOnly variant="light" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18"
              stroke="#FBFAF8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#FBFAF8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </div>
      <nav>
        <ul className="flex justify-center flex-col gap-6font-semibold leading-[28px] text-[32px] space-y-[24px]">
          {MENU_ITEMS.map((menu, indexMenu) => (
            <motion.li key={indexMenu} variants={menuItem}>
              <a href={menu.target}>{menu.name}</a>
            </motion.li>
          ))}
        </ul>
      </nav>
      <div className="text-[24px] leading-[28px] uppercase space-y-[8px] mt-[19.286vh]">
        <p onClick={onClickTwitter}>Twitter</p>
        <p onClick={onClickDiscord}>Discord</p>
      </div>
    </motion.div>
  );
}

export default MobileMenu;
