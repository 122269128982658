import { motion } from "framer-motion";
import { useCallback, useState } from "react";
import { ENVIRONMENT } from "@/utils/environment";
import BythenConnectWallet from "@/components/Header/BythenConnectWallet";
import MobileMenu from "./MobileMenu";
import TwitterIcon from "../Icon/TwitterIcon";
import DiscordIcon from "../Icon/DiscrodIcon";
import BythenNavLogo from "./BythenNavLogo";

const navConfig = {
  white: {
    color: "#FFFFFF",
    btnTryDemo: "border-white text-white",
  },
  black: {
    color: "#0E100F",
    btnTryDemo: "border-byteBlack-1000/70 text-byteBlack-1000",
  },
};

export default function Header({ isBlack, bgColor, withBorder }) {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isOpenSocial, setOpenSocial] = useState(false);

  const { btnTryDemo, color } = navConfig[isBlack ? "black" : "white"];

  const onClickTwitter = useCallback((e) => {
    e.stopPropagation();
    setOpenSocial(false);
    window.open("https://twitter.com/bythenAI", "_blank");
  }, []);

  const onClickDiscord = useCallback((e) => {
    e.stopPropagation();
    setOpenSocial(false);
    window.open("https://discord.gg/bythenai", "_blank");
  }, []);

  const onOpenWhitePaper = useCallback((e) => {
    window.open("https://bythen-ai.gitbook.io/bythen-ai", "_blank");
  }, []);

  const onCloseMenu = useCallback(() => {
    setOpenMenu(false);
  }, []);

  return (
    <>
      <header
        className={`fixed top-0 w-full z-[49] ${
          !isBlack
            ? "text-white [&_.menu-item:before]:bg-white [&_.menu-item:after]:bg-white"
            : "text-black [&_.menu-item:before]:bg-black [&_.menu-item:after]:bg-black"
        } ${bgColor ? bgColor : ""} ${
          withBorder ? "border-b-1 md:pb-8" : ""
        } px-[12px] md:px-[24px] py-[20px] md:py-[20px]`}
      >
        <div className="max-w-[1920px] w-full flex items-center justify-between transition-all m-auto">
          <BythenNavLogo isBlack={isBlack} />

          <div className="hidden lg:flex font-founders space-x-[16px] font-semibold z-[42]">
            <div
              className="px-[16px] cursor-pointer"
              onClick={onOpenWhitePaper}
            >
              WHITEPAPER
            </div>

            <div
              className="px-[16px] relative"
              onClick={(e) => {
                e.stopPropagation();
                setOpenSocial((curr) => !curr);
              }}
            >
              <div className="relative cursor-pointer flex items-center">
                <span className="mr-[4px]">SOCIAL</span>
                <div className="w-[14px] h-[14px]">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.246908 1.60085L1.28232 0.565429L6.08024 5.36335L10.8782 0.56543L11.9136 1.60085L6.08024 7.43418L0.246908 1.60085Z"
                      fill={isBlack ? "#1C1B1F" : "#FFFFFF"}
                    />
                  </svg>
                </div>
              </div>

              {isOpenSocial && (
                <motion.div
                  initial="initial"
                  animate={isOpenSocial ? "animate" : "exit"}
                  variants={{
                    initial: { opacity: 0 },
                    animate: { opacity: 1 },
                    exit: { opacity: 0 },
                  }}
                  className="flex flex-col justify-center space-y-[18px] absolute top-[calc(100%+16px)] w-[185px] left-[16px] bg-white p-[16px] border-1 border-byteBlack-1000"
                >
                  <div
                    className="cursor-pointer flex items-center space-x-[12px]"
                    onClick={onClickTwitter}
                  >
                    <TwitterIcon color="black" />
                    <span className="sans tracking-wider text-byteBlack-1000 text-[16px] leading-[20px] font-medium">
                      Twitter
                    </span>
                  </div>
                  <div
                    className="cursor-pointer flex items-center space-x-[12px]"
                    onClick={onClickDiscord}
                  >
                    <DiscordIcon color="black" />
                    <span className="sans tracking-wider text-byteBlack-1000 text-[16px] leading-[20px] font-medium">
                      Discord
                    </span>
                  </div>
                </motion.div>
              )}
            </div>
          </div>

          <div className="flex space-x-[12px] items-center">
            <button
              className={`lg:flex hidden items-center px-[16px] py-[10px] rounded-none border-1 ${btnTryDemo}`}
              onClick={() =>
                window.open(`${ENVIRONMENT.BASE_URL}/demo`, "_blank")
              }
            >
              <span className="font-founders font-semibold tracking-wide text-[14px] leading-[16px]">
                TRY DEMO
              </span>
            </button>

            <BythenConnectWallet isBlack={isBlack} disableBackground={true} />
            <button
              className="lg:hidden block p-0"
              onClick={() => setOpenMenu(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 12H21"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 6H21"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 18H21"
                  stroke={color}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>
      {isOpenMenu && (
        <MobileMenu
          onClose={onCloseMenu}
          onClickDiscord={onClickDiscord}
          onClickTwitter={onClickTwitter}
        />
      )}
    </>
  );
}
