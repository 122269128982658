import { useCallback, useState } from "react";
import { bythenLogout } from "@/api/account";
import { ConnectButton, useConnectModal } from "@rainbow-me/rainbowkit";
import Cookies from "js-cookie";
import EthIcon from "@/assets/eth-icon.png";

import { useAccount, useDisconnect } from "wagmi";

import { bythenAccount, modalDialog } from "@/components/layout";
import { Image } from "@nextui-org/image";
import { wagmiConnection } from "@/signal/layout";

const avatarUrl = `${process.env.NEXT_PUBLIC_ASSETS_URL}/general/profile-${
  Math.floor(Math.random() * 3) + 1
}.png`;

const uiConfig = {
  white: {
    btnWrapper: "border-white bg-white text-byteBlack-1000",
  },
  black: {
    btnWrapper: "border-byteBlack-1000 bg-byteBlack-1000 text-white",
  },
};

export default function BythenConnectWallet({
  className,
  isBlack,
  disableBackground,
}) {
  const [showAccount, setShowAccount] = useState(false);
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();
  const { address, connector } = useAccount();

  const { btnWrapper } = uiConfig[isBlack ? "black" : "white"];

  const onDisconnect = async () => {
    disconnect(
      {
        connector,
      },
      {
        onError(error) {
          console.log("🚀 ~ onError ~ error:", error);
        },
        async onSuccess() {
          if (Cookies.get("BYTHEN_AUTH")) {
            Cookies.remove("BYTHEN_AUTH", {
              domain: ".bythen.ai",
              path: "/",
            });
          }

          wagmiConnection.value = false;
          if (bythenAccount.value) {
            bythenAccount.value = "";
            try {
              const { access_token, account_id, account_type } =
                bythenAccount.value;
              const logout = await bythenLogout({
                access_token,
                account_id,
                account_type,
              });
            } catch (error) {
              console.log("🚀 ~ onDisconnect ~ error:", error);
            } finally {
              modalDialog.value = { ...modalDialog.value, show: false };
            }
          }
        },
        onSettled() {
          console.log("settled");
        },
      }
    );
  };

  const onShowAccount = useCallback(() => {
    setShowAccount((prev) => !prev);
  }, []);

  if (!address) {
    return (
      <button
        onClick={openConnectModal}
        className={`flex items-center pl-[12px] pr-[16px] py-[10px] rounded-none border-1 ${btnWrapper} ${className}`}
      >
        <div className="relative w-[16px] h-[16px] mr-[8px]">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.99984 10C2.35628 10 1.8065 9.772 1.3505 9.316C0.894504 8.86 0.666504 8.31022 0.666504 7.66667V2.33333C0.666504 1.68978 0.894504 1.14 1.3505 0.684C1.8065 0.228 2.35628 0 2.99984 0H10.9998C11.6434 0 12.1932 0.228 12.6492 0.684C13.1052 1.14 13.3332 1.68978 13.3332 2.33333V7.66667C13.3332 8.31022 13.1052 8.86 12.6492 9.316C12.1932 9.772 11.6434 10 10.9998 10H2.99984ZM2.99984 2.5H10.9998C11.2528 2.5 11.4915 2.53633 11.7158 2.609C11.9402 2.68167 12.1459 2.79194 12.3332 2.93983V2.33333C12.3332 1.96667 12.2026 1.65278 11.9415 1.39167C11.6804 1.13056 11.3665 1 10.9998 1H2.99984C2.63317 1 2.31928 1.13056 2.05817 1.39167C1.79706 1.65278 1.6665 1.96667 1.6665 2.33333V2.93983C1.85373 2.79194 2.0595 2.68167 2.28384 2.609C2.50817 2.53633 2.74684 2.5 2.99984 2.5ZM1.728 4.49367L9.21517 6.31283C9.30661 6.33506 9.39917 6.33611 9.49284 6.316C9.58639 6.29589 9.67123 6.25467 9.74734 6.19233L12.1473 4.17567C12.0379 3.97478 11.8812 3.81194 11.6773 3.68717C11.4736 3.56239 11.2477 3.5 10.9998 3.5H2.99984C2.68528 3.5 2.41223 3.59211 2.18067 3.77633C1.949 3.96044 1.79812 4.19956 1.728 4.49367Z"
              fill={isBlack ? "white" : "black"}
            />
          </svg>
        </div>

        <span className="font-founders font-semibold tracking-wide text-[14px] leading-[16px]">
          CONNECT WALLET
        </span>
      </button>
    );
  }

  return (
    <ConnectButton.Custom>
      {({ account, authenticationStatus, mounted }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              return (
                <>
                  <div
                    onClick={onShowAccount}
                    className="relative cursor-pointer border-1 border-[#d6d6d6] rounded-full"
                  >
                    <Image
                      className="w-[24px] aspect-square md:w-[36px] border-[7px] rounded-full"
                      src={avatarUrl}
                      alt="profile-picture"
                    />

                    {showAccount ? (
                      <>
                        <div className="absolute top-[calc(100%+16px)] right-0 bg-alabaster p-[14px] border-0 rounded-[15px] w-60">
                          <div className="pt-[10px] pb-6 flex items-center">
                            <div className="mr-[8px]">
                              <Image alt="" src={EthIcon.src} width={16} />
                            </div>

                            <span className="text-rich_black">
                              {account.displayName}
                            </span>
                          </div>
                          <button
                            className="w-full relative bg-black hover:bg-[#1f1f1f] border-0 text-alabaster text-base rounded-[56px] h-10 flex justify-center items-center overflow-hidden"
                            onClick={() => onDisconnect()}
                          >
                            Disconnect
                          </button>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
