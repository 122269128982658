import { getIconColor } from "./helper";

export default function TwitterIcon({ color }) {
  const iconColor = getIconColor(color);

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5212 2H18.3484L12.1719 9.06022L19.438 18.6667H13.7483L9.29224 12.8408L4.19275 18.6667H1.36397L7.97021 11.1157L1 2H6.83369L10.8616 7.32569L15.5212 2ZM14.5287 16.9744H16.0957L5.98286 3.60381H4.30155L14.5287 16.9744Z"
        fill={iconColor}
      />
    </svg>
  );
}
