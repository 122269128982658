import { getIconColor } from "./helper";

export default function DiscordIcon({ color }) {
  const iconColor = getIconColor(color);

  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6274 1.39583C16.1822 0.734852 14.6567 0.265852 13.0899 0.000429487C12.8773 0.381384 12.6823 0.780378 12.5105 1.18195C11.6769 1.05611 10.8355 0.992974 9.99245 0.992544C9.15109 0.992544 8.30543 1.05654 7.47394 1.18023C7.3043 0.779519 7.10716 0.382243 6.89328 0C5.32866 0.266711 3.79925 0.738717 2.35446 1.39841C-0.517519 5.64732 -1.29575 9.78929 -0.906633 13.8729C0.774808 15.1149 2.65725 16.0598 4.6578 16.6658C5.10661 16.0602 5.50861 15.4151 5.84962 14.7443C5.19852 14.5016 4.57018 14.2018 3.97191 13.8484C4.12953 13.7341 4.28329 13.6165 4.43189 13.5026C6.17131 14.3195 8.07179 14.7456 9.99417 14.7456C11.9166 14.7456 13.8175 14.3195 15.5569 13.5026C15.7072 13.6255 15.8614 13.7431 16.0169 13.8484C15.4173 14.2027 14.7881 14.5033 14.1357 14.7469C14.478 15.4194 14.877 16.0615 15.3275 16.6667C17.3302 16.0624 19.214 15.1179 20.8958 13.8741H20.8954C21.352 9.13905 20.1155 5.03487 17.6266 1.39583H17.6274ZM6.34225 11.3616C5.25779 11.3616 4.36188 10.3777 4.36188 9.16697C4.36188 7.95624 5.22644 6.9637 6.33881 6.9637C7.45118 6.9637 8.33979 7.95667 8.32089 9.16697C8.30199 10.3777 7.44732 11.3616 6.34225 11.3616ZM13.6478 11.3616C12.5616 11.3616 11.6692 10.3777 11.6692 9.16697C11.6692 7.95624 12.5342 6.9637 13.6478 6.9637C14.7615 6.9637 15.6436 7.95667 15.6247 9.16697C15.6058 10.3777 14.7529 11.3616 13.6478 11.3616Z"
        fill={iconColor}
      />
    </svg>
  );
}
