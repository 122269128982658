import { motion, useScroll, useMotionValueEvent } from "framer-motion";
import { useState } from "react";
import { ENVIRONMENT } from "@/utils/environment";
import Image from "next/image";
import { useAccount } from "wagmi";

const BythenNavLogo = ({ isBlack }) => {
  const { address } = useAccount();
  const [isScrolling, setScrolling] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    // console.log(latest, "scroll y progress headers");
    if (latest > 100) setScrolling(true);
    else setScrolling(false);
  });

  return (
    <div
      className={`min-w-auto ${
        address ? "md:min-w-[139.8px]" : "md:min-w-[254px]"
      }`}
    >
      {!isScrolling && (
        <motion.div className="overflow-hidden">
          <motion.div
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            exit={{ y: -100 }}
            transition={{ duration: 0.3, type: "tween" }}
          >
            <a href="/">
              <Image
                unoptimized
                src={
                  isBlack
                    ? `${ENVIRONMENT.ASSETS_URL}/landing/logo_black.svg`
                    : `${ENVIRONMENT.ASSETS_URL}/landing/logo.svg`
                }
                width={100}
                height={26}
                alt="bythen logo"
              />
            </a>
          </motion.div>
        </motion.div>
      )}

      {isScrolling && (
        <motion.div className="overflow-hidden">
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3, type: "tween" }}
          >
            <a href="/">
              <Image
                unoptimized
                src={
                  isBlack
                    ? `${ENVIRONMENT.ASSETS_URL}/landing/logo_small_black.svg`
                    : `${ENVIRONMENT.ASSETS_URL}/landing/logo_small_white.svg`
                }
                width={100}
                height={26}
                alt="bythen logo"
              />
            </a>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default BythenNavLogo;
