export const MENU_ITEMS = [
  {
    target: "https://bythen-ai.gitbook.io/bythen-ai",
    name: "WHITEPAPER",
  },
  {
    target: "/demo",
    name: "TRY DEMO",
  },
];
